var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"check-form"},[(_vm.isBar)?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("条形码数字")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.itemObj.barcodeValue))])]):_vm._e(),(_vm.isBar)?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("条形码格式")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.itemObj.barcodeFormat))])]):_vm._e(),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("检查要求")]),_c('div',{staticClass:"value"},[(_vm.itemObj.number)?_c('div',[_vm._v(" 扫描条形码，至少有"+_vm._s(_vm.itemObj.number)+"个扫描记录 ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.itemObj.checkDescription))])])]),(_vm.isBar)?_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-item"},[_c('div',{class:['label', _vm.itemObj.haveNumberRequire ? 'required' : '']},[_vm._v(" 扫描记录 ")])]),_c('div',{staticClass:"scan-box"},[_vm._l((_vm.form.scanList),function(item,index){return _c('div',{key:index,staticClass:"value"},[_vm._v(" "+_vm._s(item.scanTime)+" "+_vm._s(item.imgData)+" ")])}),_c('div',{staticClass:"scan-btn center",on:{"click":_vm.handleScan}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-scanning.svg"),"alt":""}})])],2)]):_vm._e(),(_vm.isBar)?_c('div',{staticClass:"info-item"},[_c('div',{class:[
        'label',
        _vm.itemObj.havePhotograghRequire === 'Y' ? 'required' : '',
      ]},[_vm._v(" 拍照要求 ")]),_c('div',{staticClass:"value-box"},[(_vm.itemObj.photoNumber)?_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.itemObj.photoNumber)+"张 ")]):_vm._e(),_c('div',{staticClass:"tip"},[_vm._v(_vm._s(_vm.itemObj.photoDescription))]),(!_vm.itemObj.photoNumber && !_vm.itemObj.photoDescription)?_c('div',{staticClass:"value"},[_vm._v(" 无 ")]):_vm._e(),_c('van-uploader',{attrs:{"after-read":_vm.handleReadImg,"preview-size":70,"upload-icon":require('@/assets/svg/icon-camera.svg')},on:{"delete":_vm.handleChange},model:{value:(_vm.form.photoList),callback:function ($$v) {_vm.$set(_vm.form, "photoList", $$v)},expression:"form.photoList"}})],1)]):_vm._e(),(_vm.isItem)?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("拍照要求")]),_c('div',{staticClass:"value-box"},[(_vm.itemObj.photoNumber || _vm.itemObj.photoNumber === 0)?_c('div',{staticClass:"value"},[_vm._v(" 1.拍照数量:"+_vm._s(_vm.itemObj.photoNumber)+"张 ")]):_vm._e(),(_vm.itemObj.photoDescription)?_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.photoDescIndex)+".拍照要求："+_vm._s(_vm.itemObj.photoDescription)+" ")]):_vm._e(),(_vm.photoExmpleUrlList && _vm.photoExmpleUrlList.length)?_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.exampleIndex)+".拍照示范图 ")]):_vm._e(),(!_vm.photoExmpleUrlList.length && _vm.exampleIndex === 1)?_c('div',{staticClass:"value"},[_vm._v(" 无 ")]):_vm._e(),_c('van-uploader',{attrs:{"readonly":"","show-upload":false,"deletable":false,"preview-size":69,"upload-icon":require('@/assets/svg/icon-camera.svg')},model:{value:(_vm.photoExmpleUrlList),callback:function ($$v) {_vm.photoExmpleUrlList=$$v},expression:"photoExmpleUrlList"}})],1)]):_vm._e(),(_vm.isItem)?_c('div',{staticClass:"info-item"},[_c('div',{class:[
        'label',
        _vm.itemObj.havePhotograghRequire === 'Y' ? 'required' : '',
      ]},[_vm._v(" 拍照上传 ")]),_c('van-uploader',{attrs:{"after-read":_vm.handleReadImg,"preview-size":69,"upload-icon":require('@/assets/svg/icon-camera.svg')},on:{"delete":_vm.handleChange},model:{value:(_vm.form.photoList),callback:function ($$v) {_vm.$set(_vm.form, "photoList", $$v)},expression:"form.photoList"}})],1):_vm._e(),((_vm.fileList && _vm.fileList.length) || (_vm.imgList && _vm.imgList.length))?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("检查项辅助文件")]),_c('div',{staticClass:"value"},[_vm._l((_vm.fileList),function(file,index){return _c('div',{key:index,staticClass:"space-between file"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(file.name))]),_c('a',{staticClass:"btn",attrs:{"target":"_blank","href":file.url}},[_vm._v("下载")])])}),_c('van-uploader',{attrs:{"readonly":"","show-upload":false,"deletable":false,"preview-size":69,"upload-icon":require('@/assets/svg/icon-camera.svg')},model:{value:(_vm.imgList),callback:function ($$v) {_vm.imgList=$$v},expression:"imgList"}})],2)]):_vm._e(),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("检查点备注")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.remarkText)+" ")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label required"},[_vm._v("检查点判定")]),_c('div',{staticClass:"radio-group"},[_c('van-radio-group',{attrs:{"direction":"horizontal"},on:{"change":_vm.handleResultChange},model:{value:(_vm.form.checkpointJudge),callback:function ($$v) {_vm.$set(_vm.form, "checkpointJudge", $$v)},expression:"form.checkpointJudge"}},_vm._l((Object.keys(_vm.CHECK_JUDGE_DICT)),function(key){return _c('van-radio',{key:key,attrs:{"name":key,"checked-color":"#055C9D","icon-size":"12"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"img-icon",attrs:{"src":props.checked
                  ? require('@/assets/svg/icon-radio-checked.svg')
                  : require('@/assets/svg/icon-radio.svg')}})]}}],null,true)},[_vm._v(" "+_vm._s(_vm.CHECK_JUDGE_DICT[key])+" ")])}),1)],1)]),_c('defect-list-popup',{attrs:{"show":_vm.isShowDefectListPop,"item-index-list":_vm.itemIndexList},on:{"on-close":_vm.toggleDefectPopup}}),_c('remark-list-popup',{attrs:{"show":_vm.isShowRemarkListPop,"item-index-list":_vm.itemIndexList,"item-obj":_vm.itemObj},on:{"on-close":_vm.toggleRemarkPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }