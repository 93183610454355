<template>
  <custom-popup
      class="remark-detail"
      :show="show"
      title="备注详情"
      @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="检查点">
        <span v-if="form.checkBigName">
          <span v-if="form.checkBigName === '其他' && form.checkChildName === '其他' && form.checkItemName === '其他'">其他</span>
          <span v-else>{{ form.checkBigName }}-{{form.checkChildName}}-{{form.checkItemName}}</span>
        </span>
        <span v-if="form.checkpointName">{{ form.checkItemName }}-{{form.checkpointName}}</span>
      </field-item>
      <field-item label="备注描述">
        <div class="value-box">
          <span>{{ form.description }}</span>
          <van-uploader
              v-if="imgList.length"
              v-model="imgList"
              readonly
              :deletable="false"
              :show-upload="false"
              :preview-size="70"
              :upload-icon="require('@/assets/svg/icon-camera.svg')"
          />
        </div>
      </field-item>
    </div>
    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">
      <van-button class="btn" size="large" color="#055C9D" plain @click="$emit('on-del')">删除</van-button>
      <van-button type="primary" class="btn" size="large" color="#055C9D" @click="$emit('on-edit')">编辑</van-button>
    </div>
<!--    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">-->
<!--      <van-button class="block-btn" color="#055C9D" block @click="$emit('on-close')">返回</van-button>-->
<!--    </div>-->
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import FieldItem from "@/components/Common/FieldItem.vue";

export default {
  name: "RemarkDetailPopup",
  components: {FieldItem, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      disabled: false,
      imgList: []
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val){
        if(val){
          this.imgList = this.form.img ? this.form.img.split(',').map(item => {
            return {
              content: item,
              url: item
            }
          }) : []
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.remark-detail {
  .pop-content {
    min-height: 45vh;
  }
  .value-box{
    display: flex;
    flex-direction: column;
    span{
      margin-bottom: 5px;
    }
  }
  .btn-wrapper {
    margin-top: 20px;

    .btn, .primary-btn {
      width: 163px;
    }

    &.disabled {
      visibility: hidden;
    }
  }
}
</style>
