<template>
  <div class="counter vertical-center">
    <div class="left center" @click="handleChange(-1)">
<!--      <img src="~@/assets/svg/icon-reduce.svg" alt="">-->
      <van-icon name="minus" :class="{
        'disabled-icon': value <= min
      }"/>
    </div>
    <input
        ref="numInput"
        type="number"
        :value="value"
        class="num-input"
        :disabled="disabled"
        @input="handleInput"
        @blur="handleInputBlur"
    />
    <div class="right center" @click="handleChange(1)">
<!--      <img src="~@/assets/svg/icon-add.svg" alt="">-->
      <van-icon name="plus" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Counter',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleChange(step) {
      if(!this.disabled) {
        const newValue = this.value + step
        if ((newValue <= this.max) && (newValue >= this.min)) {
          this.$emit('change', newValue)
        }
      }
    },
    handleInput() {
      const newValue = Number(this.$refs.numInput.value)
      if (!isNaN(newValue)) {
        this.$emit('change', newValue)
      }
    },
    handleInputBlur(){
      window.scroll(0, 0);
    },
  }
}
</script>

<style scoped lang="less">
.counter {
  .left, .right {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #DCDCDC;
    .van-icon {
      color: #000000;
      &.disabled-icon {
        color: #DCDCDC;
      }
    }
  }

  .num-input {
    width: 80px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #DCDCDC;
    margin: 0 4px;
    text-align: center;
  }
  img {
    width: 16px;
    height: 16px;
  }
}
</style>
