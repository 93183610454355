<template>
  <div class="step4">
    <div class="step-content">
      <step-common-info :step="4"/>
      <!-- <TaskInfoCard></TaskInfoCard> -->
      <card title="验货信息">
        <field-item label="单品货号">
          <span>{{ orderItemDetail.singleProductNo }}</span>
        </field-item>
        <field-item label="单品名称">
          <span>{{orderItemDetail.singleProductName}}</span>
        </field-item>
        <field-item label="订单号">
          <span>{{ orderItemDetail.orderNo }}</span>
        </field-item>
        <field-item label="申请数量">
          <span>{{ orderItemDetail.inspectQuantity }}</span>
        </field-item>
        <field-item label="点货数量">
          <span>{{ orderItemDetail.orderQuantity }}</span>
        </field-item>
        <field-item label="抽检水平">
          <span>{{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>
        </field-item>
        <field-item label="抽检数量">
          <span>{{ orderItemDetail.sampleQuantity }}</span>
        </field-item>
        <field-item label="缺陷总计">
          <span>CRI {{total.aqlCri}}  MAJ {{total.aqlMaj}}  MIN {{total.aqlMin}}</span>
        </field-item>
        <field-item label="验货结论">
          <span>{{ INSPECT_DICT[orderItemDetail.inspectConclusion] }}</span>
        </field-item>
        <field-item label="验货备注">
          <span>{{ orderItemDetail.inspectRemark }}</span>
        </field-item>
      </card>
      <defect-statistic-table :order-item-id="orderItemId" @on-total="handleTotalComputed"/>
      <check-result-table :order-item-id="orderItemId"/>
    </div>
    <div class="btn-wrapper space-between" v-if="orderItemDetail.supplierComments=='AGREE'||orderItemDetail.supplierComments=='AUTO_CONFIRM'">
      <!-- <van-button class="btn" color="#055C9D" plain @click="$emit('on-previous')">上一步</van-button> -->
      <van-button :style="{width:'100%'}" class="btn" color="#055C9D" block @click="handleNavToIndex">返回首页</van-button>
    </div>
    <div v-else class="btn-wrapper space-between">
      <van-button class="btn" color="#055C9D" plain @click="$emit('on-previous')">上一步</van-button>
      <van-button class="btn" color="#055C9D" block @click="handleNavToIndex">返回首页</van-button>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import TaskInfoCard from '@/components/TaskDetail/TaskInfoCard';

import DefectStatisticTable from "@/components/Common/DefectStatisticTable.vue";
import CheckResultTable from "@/components/Common/CheckResultTable.vue";
import StepCommonInfo from './StepCommonInfo';
import {mapGetters} from 'vuex';
import {INSPECT_DICT, SAMPLING_LEVEL_DICT} from '@/constants';

export default {
  name: "Step4",
  components: {StepCommonInfo, TaskInfoCard, DefectStatisticTable, CheckResultTable, FieldItem, Card},
  props: {
    orderItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      INSPECT_DICT,
      SAMPLING_LEVEL_DICT,
      total: {
        aqlCri: null,
        aqlMaj: null,
        aqlMin: null,
      },
    }
  },
  computed: {
    ...mapGetters(['orderItemDetail', 'taskDetail']),
  },
  methods: {
    handleTotalComputed(aqlCri, aqlMaj, aqlMin){
      this.total = {aqlCri, aqlMaj, aqlMin}
    },
    handleCall(phone) {
      window.location.href = `tel:${phone}`
    },
    handleNavToIndex() {
      this.$router.replace({name: 'taskDetail', query: {...this.$route.query}})
    }
  }
}
</script>

<style scoped lang="less">
.step4::v-deep {
  padding-bottom: 80px;
  .btn-wrapper {
    width: 100vw;
    padding: 12px 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    margin-top: 22px;

    // .btn {
    //   width: 100%;
    // }
    .btn {
      width: calc(50% - 8px);
    }
  }
}
</style>
