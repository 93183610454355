<template>
  <card title="缺陷统计">
    <custom-table v-loading="loading" :columns="columns" :table-data="tableData"/>
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import CustomTable from "@/components/Common/CustomTable.vue";
import {getAction} from '@/api/manage';
import {mapGetters} from 'vuex';

export default {
  name: "DefectStatisticTable",
  components: {CustomTable, Card},
  props: {
    orderItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      columns: [
        '缺陷类别',
        '缺陷名称',
        'CRI',
        'MAJ',
        'MIN',
      ],
      tableData: []
    }
  },
  created() {
    this.getDefect()
  },
  computed: {
    ...mapGetters(['orderItemDetail']),
  },
  methods: {
    async getDefect() {
      this.tableData = []
      this.loading = true
      const res = await getAction('/jeecg-system/task/taskDefect/queryByItemId', {
        taskOrderItemId: this.orderItemId
      })
      this.loading = false
      if (res.success) {
        Object.keys(res.result).forEach(key => {
          let children = res.result[key]
          if (children?.length) {
            children.forEach((item, index) => {
              let title = index === 0 ? key : ''
              this.tableData.push([
                title, item.name, item.aqlCri, item.aqlMaj, item.aqlMin
              ])
            })
          }
        })
        const aqlCri = this.tableData.reduce((prev, cur) => {
          return prev + cur[2]
        }, 0)
        const aqlMaj = this.tableData.reduce((prev, cur) => {
          return prev + cur[3]
        }, 0)
        const aqlMin = this.tableData.reduce((prev, cur) => {
          return prev + cur[4]
        }, 0)
        this.tableData.push(['总计', '', aqlCri, aqlMaj, aqlMin])
        this.$emit('on-total', aqlCri, aqlMaj, aqlMin)
        this.tableData.push(['参考值', '', this.orderItemDetail.aqlCri, this.orderItemDetail.aqlMaj, this.orderItemDetail.aqlMin])
      } else this.Toast.fail(res.message)
    },
  },
}
</script>

<style scoped>

</style>
