<template>
  <div class="step2">
    <div class="step-content">
      <step-common-info :step="2" />

      <div class="inspection_cell">
        <van-cell
          v-for="(item, index) in checkItemList"
          :key="index"
          :title="item.checkName + '-' + item.checkPointName"
          :value="
            item.taskInspection
              ? CHECK_JUDGE_DICT[item.taskInspection.checkpointJudge]
              : ''
          "
          :class="
            item.taskInspection ? item.taskInspection.checkpointJudge : 'normal'
          "
          @click="handSingleProductPopup(item)"
        >
          <template #icon>
            <div class="checkpoint_judge"></div>
          </template>
          <template #right-icon>
            <van-icon
              @click.stop="handleItemDelClick(item)"
              v-if="item.checkpointType == CHECKPOINT_CUSTOM"
              name="delete-o"
            />
            <van-icon v-else name="arrow" />
          </template>
        </van-cell>
      </div>
      <!-- <van-collapse v-model="active" class="collapse-box">
        <van-collapse-item
            v-for="(item1, index1) in checkItemList"
            :key="`${index1}_${item1.itemName}`"
            :name="index1"
            :lazy-render="false">
          <div class="check-item check-item-1 vertical-center" slot="title">
            <img :class="{'is-active':  active.includes(index1), 'caret-img': true}" src="~@/assets/svg/icon-caret.svg"
                 alt=""
            >
            <div>{{ item1.itemName }}</div>
          </div>
          <van-collapse v-if="item1.children && item1.children.length" v-model="item1.active">
            <van-collapse-item v-for="(item2, index2) in  item1.children" :key="`${index2}_${item2.itemName}`"
                               :name="index2"
                               :lazy-render="false">
              <div class="check-item check-item-2 vertical-center" slot="title">
                <img
                    :class="{'is-active':  item1.active && item1.active.includes(index2), 'caret-img': true}"
                    src="~@/assets/svg/icon-caret.svg"
                >
                <div>{{ item2.itemName }}</div>
                <div class="right-box">
                  <span>{{ item2.checkpointJudge ? CHECK_JUDGE_DICT[item2.checkpointJudge] : '' }}</span>
                  <img v-if="item2.checkpointType === CHECKPOINT_CUSTOM" src="~@/assets/svg/icon-delete.svg" alt=""
                       class="del-img"
                       @click.stop="handleItemDelClick(item2)">
                </div>
              </div>
              <van-collapse v-if="item2.children && item2.children.length && !isHiddenThree(item2)"
                            v-model="item2.active">
                <van-collapse-item v-for="(item3, index3) in  item2.children" :key="`${index3}_${item3.itemName}`"
                                   :name="index3"
                                   :lazy-render="false">
                  <div class="check-item check-item-3 vertical-center" slot="title">
                    <img
                        :class="{'is-active':  item2.active && item2.active.includes(index3), 'caret-img': true}"
                        src="~@/assets/svg/icon-caret.svg"
                        alt="">
                    <div>{{ item3.itemName }}</div>
                    <div class="right-box">
                      <span>{{ item3.checkpointJudge ? CHECK_JUDGE_DICT[item3.checkpointJudge] : '' }}</span>
                    </div>
                  </div>
                  <check-form :ref="`form_${item3.children[0].id}`" :item-obj="item3.children[0]"
                              :item-index-list="[index1, index2, index3]" @change="handleFormChange"
                              @resultChange="handleJudgeChange($event, [index1, index2, index3])"></check-form>
                </van-collapse-item>
              </van-collapse>
              <check-form v-else-if="isHiddenThree(item2)" :ref="`form_${item2.children[0].children[0].id}`"
                          :item-obj="item2.children[0].children[0]" :item-index-list="[index1, index2]"
                          @change="handleFormChange"
                          @resultChange="handleJudgeChange($event, [index1, index2])"></check-form>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse> -->
    </div>
    <!-- <div class="right-fixed-btn">
      <van-button icon="plus" type="primary" color="#055C9D" @click="toggleDefectPopup">缺陷</van-button>
      <van-button icon="plus" type="primary" color="#055C9D" @click="toggleRemarkPopup">备注</van-button>
    </div> -->
    <div class="fixed-btn">
      <div class="btn-box space-between vertical-center set_btn_box">
        <van-button
          type="primary"
          color="#055C9D"
          class="step-btn"
          icon="plus"
          plain
          @click="toggleRemarkPopup"
          >备注</van-button
        >
        <van-button
          type="primary"
          color="#055C9D"
          class="step-btn"
          icon="plus"
          plain
          @click="toggleDefectPopup"
          >缺陷记录</van-button
        >
        <van-button
          type="primary"
          color="#055C9D"
          class="step-btn"
          icon="plus"
          plain
          @click="togglePopup"
          >自定义检查点</van-button
        >
      </div>
      <div class="btn-box space-between vertical-center">
        <!-- <van-button
          type="primary"
          color="#055C9D"
          plain
          class="step-btn"
          @click="$emit('on-previous')"
          >上一步</van-button
        > -->
        <van-button
          type="primary"
          :loading="btnLoading"
          loading-text="提交中"
          color="#055C9D"
          @click="handleSubmit"
        >
          提交全部检验结果
        </van-button>
      </div>
    </div>
    <NewCheckPointPopup
      :show="showPopup"
      :single-product-id="singleProductId"
      :product-base-info-id="
        orderItemDetail.productBaseInfo && orderItemDetail.productBaseInfo.id
      "
      @on-close="togglePopup"
      @on-ok="handlePopupOk"
    ></NewCheckPointPopup>

    <TaskSingleProductPopup
      ref="TaskSingleProductPopup"
      :single-product-id="singleProductId"
      :product-base-info-id="
        orderItemDetail.productBaseInfo && orderItemDetail.productBaseInfo.id
      "
      @ok="setItemList"
    ></TaskSingleProductPopup>
    <defect-list-popup
      :show="isShowDefectListPop"
      @on-close="toggleDefectPopup"
    />
    <remark-list-popup
      :show="isShowRemarkListPop"
      @on-close="toggleRemarkPopup"
    />
  </div>
</template>

<script>
import {
  CHECK_TYPE_P_BAR,
  CHECK_TYPE_P_ITEM,
  CHECK_TYPE_S_BAR,
  CHECK_TYPE_S_ITEM,
  CHECKPOINT_ATTRIBUTE,
  CHECKPOINT_CUSTOM,
  CHECK_JUDGE_DICT,
} from "@/constants";
import TaskSingleProductPopup from "@/components/TaskCheck/TaskSingleProductPopup.vue";
import NewCheckPointPopup from "@/components/TaskCheck/NewCheckPointPopup.vue";
import CheckForm from "@/components/TaskCheck/CheckForm.vue";
import DefectListPopup from "@/components/Defect/DefectListPopup";
import RemarkListPopup from "@/components/Remark/RemarkListPopup";
import StepCommonInfo from "./StepCommonInfo";
import { formatCheckList } from "@/utils/util";
import { deleteAction, getAction, postAction } from "@/api/manage";
import { mapGetters } from "vuex";

export default {
  name: "Step2",
  components: {
    StepCommonInfo,
    RemarkListPopup,
    DefectListPopup,
    NewCheckPointPopup,
    CheckForm,
    TaskSingleProductPopup,
  },
  inject: ["singleProductId"],
  props: {
    orderItemId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      CHECKPOINT_CUSTOM,
      active: [],
      checkItemList: [],
      showPopup: false,
      btnLoading: false,
      CHECK_JUDGE_DICT,
      localList: [],
      cacheKey: "",
      isShowDefectListPop: false,
      isShowRemarkListPop: false,
      showSingleProductPopup: false,
    };
  },
  computed: {
    ...mapGetters(["orderItemDetail"]),
    isHiddenThree() {
      return function (item) {
        return (
          item.checkpointType === CHECKPOINT_CUSTOM ||
          [CHECK_TYPE_S_ITEM, CHECK_TYPE_S_BAR, CHECK_TYPE_P_BAR].includes(
            item.checkType
          )
        );
      };
    },
  },
  mounted() {
    this.cacheKey = `${this.orderItemId}_cache`;
    let localListStr = window.localStorage.getItem(this.cacheKey);
    this.localList = localListStr ? JSON.parse(localListStr) : [];
    this.getSingleProductCheck();
  },
  methods: {
    setItemList(form) {
      console.log(form);
      this.checkItemList.map((i) => {
        if (i.id == form.id) i = form;
      });
      this.$forceUpdate();
      console.log(this.checkItemList);

      let index = this.localList.findIndex((item) => item.id === form.id);
      console.log(index);
      if (index > -1) {
        this.localList[index] = form;
      } else this.localList.push(form);
      window.localStorage.setItem(
        this.cacheKey,
        JSON.stringify(this.localList)
      );
    },
    handSingleProductPopup(item) {
      // this.showSingleProductPopup=true
      this.$refs.TaskSingleProductPopup.show = true;
      this.$refs.TaskSingleProductPopup.singleProduct = item;
      this.$refs.TaskSingleProductPopup.title =
        item.checkName + "-" + item.checkPointName;
      this.$refs.TaskSingleProductPopup.setCheckForm(item);
    },
    async handleSubmit() {
      console.log("handleSubmit....");
      let valid = true;
      // this.checkItemList.some((item1, index1) => {
      //   let result1 = false;
      //   item1.children.some((item2, index2) => {
      //     let result2 = false;
      //     item2.children.some((item3, index3) => {
      //       let result3 = false;
      //       item3.children.some((item4) => {
      //         let refName = `form_${item4.id}`;
      //         console.log(refName, this.$refs[refName]);
      //         const valid = this.$refs[refName][0].validate();
      //         if (!valid) {
      //           result3 = true;
      //           if (!this.active.includes(index1)) this.active.push(index1);
      //           if (!item1.active.includes(index2)) item1.active.push(index2);
      //           if (!item2.active.includes(index3)) item2.active.push(index3);
      //           return true;
      //         }
      //         return false;
      //       });
      //       if (result3 === true) result2 = true;
      //       return result3;
      //     });
      //     if (result2 === true) result1 = true;
      //     return result2;
      //   });
      //   if (result1) valid = false;
      //   return result1;
      // });
      for (let i of this.checkItemList) {
        let checkName = `${i.checkName}-${i.checkPointName}`;
        if (i.taskInspection && !i.taskInspection.checkpointJudge)
          return this.Toast.fail(`${checkName}未完成检验`);
        if (!i.taskInspection) return this.Toast.fail(`${checkName}未完成检验`);
      }
      if (valid) {
        let taskInspectionList = [];
        this.checkItemList.forEach((item, index) => {
          // item1.children.forEach((item2, index2) => {
          //   item2.children.forEach((item3, index3) => {
          //     item3.children.forEach((item4) => {
          //       let form = this.$refs[`form_${item4.id}`][0].form;
          //       const taskInspectionExpands = form.scanList.map((item) => item);
          //       const images = form.photoList.map((item) => item.url).join(",");
          //       let checkData = {
          //         checkItemName: item4.checkBigName,
          //         checkpointName: item4.checkChildName,
          //       };
          //       if (item4.checkType === CHECK_TYPE_P_ITEM) {
          //         checkData = {
          //           checkBigName: item4.checkBigName,
          //           checkChildName: item4.checkChildName,
          //           checkItemName: item4.checkName,
          //         };
          //       }
          let form = item.taskInspection;
          let checkData = {
            checkItemName: item.checkName,
            checkpointName: item.checkPointName,
          };
          const taskInspectionExpands = form.scanList
            ? form.scanList.map((item) => item)
            : [];
          const images = form.photoList
            ? form.photoList.map((item) => item.url).join(",")
            : '';
          taskInspectionList.push({
            ...checkData,
            checkType: item.checkType,
            images,
            taskInspectionExpands,
            checkpointJudge: form.checkpointJudge,
            taskOrderItemId: this.orderItemId,
            orderId: this.orderItemDetail.orderId,
            taskId: this.orderItemDetail.taskId,
            id: item.taskInspection?.id,
          });
          //     });
          //   });
          // });
        });
        window.localStorage.removeItem(this.cacheKey);
        this.btnLoading = true;
        const res = await postAction(
          `/jeecg-system/task/taskInspection/${this.orderItemId}/add`,
          taskInspectionList
        );
        this.btnLoading = false;
        if (res.success) {
          this.$emit("on-next");
        } else this.Toast.fail(res.message);
      }
    },
    handleItemDelClick(item) {
      this.Dialog.confirm({
        title: "",
        message: "确认删除自定义检查点？",
      })
        .then(async () => {
          const res = await deleteAction(
            "/jeecg-system/admin/singleProductCheck/delete",
            {
              id: item.id,
            }
          );
          if (res.success) {
            this.getSingleProductCheck();
            this.Toast.success(res.message)
          } else this.Toast.fail(res.message);
        })
        .catch(() => {});
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    handlePopupOk() {
      this.getSingleProductCheck();
      this.showPopup = !this.showPopup;
    },
    toggleDefectPopup() {
      this.isShowDefectListPop = !this.isShowDefectListPop;
    },
    toggleRemarkPopup() {
      this.isShowRemarkListPop = !this.isShowRemarkListPop;
    },
    handleFormChange(form) {
      let index = this.localList.findIndex((item) => item.id === form.id);
      if (index > -1) {
        this.localList[index] = form;
      } else this.localList.push(form);
      window.localStorage.setItem(
        this.cacheKey,
        JSON.stringify(this.localList)
      );
    },
    handleJudgeChange(val, list) {
      if (list.length === 2) {
        this.$set(
          this.checkItemList[list[0]].children[list[1]],
          "checkpointJudge",
          val
        );
      } else if (list.length === 3) {
        this.$set(
          this.checkItemList[list[0]].children[list[1]].children[list[2]],
          "checkpointJudge",
          val
        );
      }
    },
    async getSingleProductCheck() {
      this.Toast.allowMultiple();
      this.Toast.loading({
        message: "加载中",
      });
      const res = await getAction(
        "/jeecg-system/admin/singleProductInfo/queryCheckById",
        {
          id: this.singleProductId,
          taskOrderItemId: this.orderItemId,
        }
      );
      this.Toast.clear();
      if (res.success) {
        console.log(res.result);
        this.checkItemList = formatCheckList(res.result);
        // this.checkItemList = res.result;
        this.checkItemList.map((i) => {
          this.localList.map((j) => {
            if (i.id == j.id) {
              i.taskInspection = j?.taskInspection;
            }
          });
        });
        this.$store.state.checkItemList = this.checkItemList;
      } else {
        this.Toast.fail(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.step2 {
  .inspection_cell {
    .van-icon {
      line-height: 24px;
    }
  }
  .step-content {
    height: calc(100vh - 44px) !important;
  }
  .fixed-btn .set_btn_box {
    margin-top: 0 !important;
    .step-btn {
      padding-left: 8px;
      padding-right: 8px;
      width: auto !important;
      height: 40px;
    }
  }
  .collapse-box {
    margin-top: 16px;

    .check-item {
      position: relative;

      .caret-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        &.is-active {
          transform: rotate(90deg);
        }
      }

      &.check-item-2 .caret-img {
        margin-left: 32px;
      }

      &.check-item-3 .caret-img {
        margin-left: 60px;
      }

      .right-box {
        position: absolute;
        right: 40px;

        .del-img {
          margin-left: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }

    .check-detail {
      white-space: pre-line;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      text-align: left;
      line-height: 22px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;

      &.check-detail-1 {
        padding-left: 64px;
      }

      &.check-detail-2 {
        padding-left: 80px;
      }

      &.check-detail-3 {
        padding-left: 96px;
      }
    }
  }

  .right-fixed-btn {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    top: calc(50vh - 40px);

    button {
      i {
        margin-right: -3px;
        font-size: 12px;
      }
      margin-bottom: 8px;
      height: 34px;
      background: #055c9d;
      border-radius: 20px 0 0 20px;
      font-family: SourceHanSansSC-Regular;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: 0;
      line-height: 22px;
      padding: 0 5px;
    }
  }

  .fixed-btn {
    .btn-box {
      margin-top: 20px;

      .van-button {
        // width: calc((100% - 17px) / 2);
        width: 100%;
      }
    }
  }

  ::v-deep {
    .van-collapse-item__content {
      padding: 0;
    }

    .van-cell {
      padding: 12px 8px;
      border-bottom: 1px solid #dfe4eb;
      .van-cell__title,
      .van-cell__value {
        flex: unset;
      }
      .van-cell__title {
        width: 80%;
      }
      .van-cell__value {
        width: 20%;
      }
      .checkpoint_judge {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 7px;
        margin-right: 10px;
      }
      &.QUALIFIED {
        .van-cell__value {
          color: #00bf46;
        }
        .checkpoint_judge {
          background: #00bf46;
        }
      }
      &.FAILED {
        .van-cell__value {
          color: #f94837;
        }
        .checkpoint_judge {
          background: #f94837;
        }
      }
      &.PENDING {
        .van-cell__value {
          color: #ffad16;
        }
        .checkpoint_judge {
          background: #ffad16;
        }
      }
      &.UNCHECKED {
        .van-cell__value {
          color: #055c9d;
        }
        .checkpoint_judge {
          background: #055c9d;
        }
      }
    }

    // .van-cell::after,
    // .van-cell__right-icon {
    //   display: none;
    // }
  }
}
</style>
