<template>
  <custom-popup
      class="defect-detail"
      :show="show"
      title="缺陷详情"
      @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="缺陷类别">
      <span>
        {{form.firstCategory ? `${form.firstCategory}/${form.secondaryCategory}/${form.tertiaryCategory}` : '' }}
      </span>
      </field-item>
      <field-item label="缺陷名称">
        <span>{{ form.name }}</span>
      </field-item>
      <field-item label="缺陷描述">
        <div class="value-box">
          <span>{{ form.description }}</span>
          <van-uploader
              v-if="imgList.length"
              v-model="imgList"
              readonly
              :deletable="false"
              :show-upload="false"
              :preview-size="70"
              :upload-icon="require('@/assets/svg/icon-camera.svg')"
          />
        </div>
      </field-item>
      <field-item label="缺陷统计">
        <div class="statistic-wrapper">
          <div class="statistic-item vertical-center">
            <div class="name">致命（ CRI ）</div>
            <div class="count">{{ form.aqlCri }}</div>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">严重（ MAJ）</div>
            <div class="count">{{ form.aqlMaj }}</div>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">轻微（ MIN ）</div>
            <div class="count">{{ form.aqlMin }}</div>
          </div>
        </div>
      </field-item>
    </div>
    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">
      <van-button class="btn" size="large" color="#055C9D" plain @click="$emit('on-del')">删除</van-button>
      <van-button type="primary" size="large" class="btn" color="#055C9D" @click="$emit('on-edit')">编辑</van-button>
    </div>
<!--    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">-->
<!--      <van-button class="block-btn" color="#055C9D" block @click="$emit('on-close')">返回</van-button>-->
<!--    </div>-->
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import FieldItem from "@/components/Common/FieldItem.vue";

export default {
  name: "DefectDetailPopup",
  components: {FieldItem, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      disabled: false,
      imgList: []
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.imgList = this.form.img ? this.form.img.split(',').map(item => {
            return {
              content: item,
              url: item
            }
          }) : []
        }
      }
    }
  },
}
</script>

<style scoped lang="less">
.defect-detail {
  .pop-content {
    min-height: 45vh;
    .value-box {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 5px;
      }
    }

    .statistic-item {
      font-size: 14px;
      color: rgba(#000, 0.9);
      margin-bottom: 8px;

      .name {
        margin-right: 8px;
      }
    }

    .img {
      width: 70px;
      height: 70px;
    }
  }

  .btn-wrapper {
    margin-top: 20px;

    .btn {
      width: 163px;
    }

    &.disabled {
      visibility: hidden;
    }
  }
}
</style>
