<template>
  <div class="custom-table">
    <div class="th space-between">
      <div v-for="(item, index) in columns" :key="index" class="th-cell cell center">{{ item }}</div>
    </div>
    <div v-for="(td, index) in tableData" :key="index" class="td space-between" :class="{'statistic-row': isStatisticRow(td)}">
      <div v-for="(item, index2) in td" :key="index2" class="td-cell cell center" :class="resultClass(item)">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTable",
  props: {
    columns: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    }
  },
  computed: {
    resultClass() {
      return (value) => {
        switch (value) {
          case '合格':
            return 'qualified'
          case '不合格':
            return 'invalid'
          case '待定':
            return 'to-be-continue'
          case '未检':
            return 'to-be-continue'
          default:
            return ''
        }
      }
    },
    isStatisticRow() {
      return (row) => {
        const first = row.find((item) => item === '总计')
        const second = row.find((item) => item === '参考值')
        return first || second
      }
    }
  }
}
</script>

<style scoped lang="less">
.custom-table {
  .th-cell {
    background-color: #F9FAFA;
  }
  .cell {
    min-height: 40px;
    flex: 1;
    padding: 0 6px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
  }
  .qualified{
    color: #00BF46;
  }
  .invalid {
    color: #E34D59;
  }
  .to-be-continue {
    color: #FFAD16;
  }
  .statistic-row .cell {
    border-bottom: none;
    color: #4B4E51;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
