<template>
  <div class="step3">
    <div class="step-content">
      <step-common-info :step="3"/>
      <check-result-table :order-item-id="orderItemId"/>
      <defect-statistic-table :order-item-id="orderItemId"/>
      <card title="验货结论" :show-divider="false">
        <van-radio-group v-model="form.inspectStatus" direction="horizontal">
          <van-radio v-for="key in Object.keys(INSPECT_DICT)" :key="key" :name="key" checked-color="#055C9D"
                     icon-size="12">
            <template #icon="props">
              <img class="img-icon"
                   :src="props.checked ? require('@/assets/svg/icon-radio-checked.svg') : require('@/assets/svg/icon-radio.svg')"/>
            </template>
            {{ INSPECT_DICT[key] }}
          </van-radio>
        </van-radio-group>
        <el-input v-model="form.reason" placeholder="请输入" type="textarea" :rows="4" style="margin-top: 8px"  @blur="handleInputBlur"/>
      </card>
    </div>
    <div class="btn-wrapper space-between">
      <van-button class="btn" color="#055C9D" plain @click="$emit('on-previous')">上一步</van-button>
      <van-button class="btn" color="#055C9D" :loading="submitLoading" loading-text="提交中" type="primary"
                  @click="submitForm">提交验货结论
      </van-button>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import CheckResultTable from "@/components/Common/CheckResultTable.vue";
import DefectStatisticTable from "@/components/Common/DefectStatisticTable.vue";
import StepCommonInfo from './StepCommonInfo';
import {postAction, getAction} from '@/api/manage';
import {INSPECT_DICT} from '@/constants'
import {mapGetters} from 'vuex'

export default {
  name: "Step3",
  components: {StepCommonInfo, DefectStatisticTable, CheckResultTable, Card},
  props: {
    orderItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      INSPECT_DICT,
      form: {
        inspectStatus: '',
        reason: '',
      },
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters(['orderItemDetail'])
  },
  watch: {
    orderItemDetail: {
      immediate: true,
      handler: function (){
        if(this.orderItemDetail.inspectConclusion){
          this.form.inspectStatus = this.orderItemDetail.inspectConclusion
          this.form.reason = this.orderItemDetail.inspectRemark
        }
      }
    }
  },
  methods: {
    handleInputBlur(){
      window.scroll(0, 0);
    },
    async submitForm() {
      if(!this.form.inspectStatus){
        this.Toast.fail('请选择验货结论')
        return
      }
      this.submitLoading = true
      const res = await postAction('/jeecg-system/admin/taskBaseInfo/add_inspect_info', {
        id: this.orderItemId,
        inspectStatus: this.INSPECT_DICT[this.form.inspectStatus],
        reason: this.form.reason
      })
      this.submitLoading = false
      if(res.success){
        this.$emit('on-nav-change')
        this.$emit('on-next')
      }else this.Toast.fail(res.message)
    }
  }
}
</script>

<style scoped lang="less">
.step3::v-deep {
  padding-bottom: 80px;

  .btn-wrapper {
    width: 100vw;
    padding: 12px 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    margin-top: 22px;

    .btn {
      width: calc(50% - 8px);
    }
  }

  .img-icon {
    width: 12px;
    height: 12px;
  }

  .van-radio-group {
    display: flex;
    justify-content: space-between;

    .van-radio {
      margin-right: 0;
    }
  }

  .el-textarea__inner {
    background-color: #F9F9F9;
    border: none;
  }
}
</style>
