<template>
  <div class="check-form">
    <div v-if="isBar" class="info-item">
      <div class="label">条形码数字</div>
      <div class="value">{{ itemObj.barcodeValue }}</div>
    </div>
    <div v-if="isBar" class="info-item">
      <div class="label">条形码格式</div>
      <div class="value">{{ itemObj.barcodeFormat }}</div>
    </div>
    <div class="info-item">
      <div class="label">检查要求</div>
      <div class="value">
        <div v-if="itemObj.number">
          扫描条形码，至少有{{ itemObj.number }}个扫描记录
        </div>
        <div>{{ itemObj.checkDescription }}</div>
      </div>
    </div>
    <div v-if="isBar" class="info">
      <div class="info-item">
        <div :class="['label', itemObj.haveNumberRequire ? 'required' : '']">
          扫描记录
        </div>
      </div>
      <div class="scan-box">
        <div v-for="(item, index) in form.scanList" :key="index" class="value">
          {{ item.scanTime }} {{ item.imgData }}
        </div>
        <div class="scan-btn center" @click="handleScan">
          <img src="~@/assets/svg/icon-scanning.svg" alt="" />
        </div>
      </div>
    </div>
    <div v-if="isBar" class="info-item">
      <div
        :class="[
          'label',
          itemObj.havePhotograghRequire === 'Y' ? 'required' : '',
        ]"
      >
        拍照要求
      </div>
      <div class="value-box">
        <div v-if="itemObj.photoNumber" class="value">
          {{ itemObj.photoNumber }}张
        </div>
        <div class="tip">{{ itemObj.photoDescription }}</div>
        <div
          v-if="!itemObj.photoNumber && !itemObj.photoDescription"
          class="value"
        >
          无
        </div>
        <van-uploader
          v-model="form.photoList"
          :after-read="handleReadImg"
          :preview-size="70"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
          @delete="handleChange"
        />
      </div>
    </div>
    <div v-if="isItem" class="info-item">
      <div class="label">拍照要求</div>
      <div class="value-box">
        <div
          v-if="itemObj.photoNumber || itemObj.photoNumber === 0"
          class="value"
        >
          1.拍照数量:{{ itemObj.photoNumber }}张
        </div>
        <div v-if="itemObj.photoDescription" class="value">
          {{ photoDescIndex }}.拍照要求：{{ itemObj.photoDescription }}
        </div>
        <div
          v-if="photoExmpleUrlList && photoExmpleUrlList.length"
          class="value"
        >
          {{ exampleIndex }}.拍照示范图
        </div>
        <div
          v-if="!photoExmpleUrlList.length && exampleIndex === 1"
          class="value"
        >
          无
        </div>
        <van-uploader
          v-model="photoExmpleUrlList"
          readonly
          :show-upload="false"
          :deletable="false"
          :preview-size="69"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </div>
    </div>
    <div v-if="isItem" class="info-item">
      <div
        :class="[
          'label',
          itemObj.havePhotograghRequire === 'Y' ? 'required' : '',
        ]"
      >
        拍照上传
      </div>
      <van-uploader
        v-model="form.photoList"
        :after-read="handleReadImg"
        :preview-size="69"
        :upload-icon="require('@/assets/svg/icon-camera.svg')"
        @delete="handleChange"
      />
    </div>
    <div
      v-if="(fileList && fileList.length) || (imgList && imgList.length)"
      class="info-item"
    >
      <div class="label">检查项辅助文件</div>
      <div class="value">
        <div
          v-for="(file, index) in fileList"
          :key="index"
          class="space-between file"
        >
          <div class="name">{{ file.name }}</div>
          <a target="_blank" :href="file.url" class="btn">下载</a>
        </div>
        <van-uploader
          v-model="imgList"
          readonly
          :show-upload="false"
          :deletable="false"
          :preview-size="69"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </div>
    </div>
    <div class="info-item">
      <div class="label">检查点备注</div>
      <div class="value">
        {{ remarkText }}
      </div>
    </div>
    <div class="info-item">
      <div class="label required">检查点判定</div>
      <div class="radio-group">
        <van-radio-group
          v-model="form.checkpointJudge"
          direction="horizontal"
          @change="handleResultChange"
        >
          <van-radio
            v-for="key in Object.keys(CHECK_JUDGE_DICT)"
            :key="key"
            :name="key"
            checked-color="#055C9D"
            icon-size="12"
          >
            <template #icon="props">
              <img
                class="img-icon"
                :src="
                  props.checked
                    ? require('@/assets/svg/icon-radio-checked.svg')
                    : require('@/assets/svg/icon-radio.svg')
                "
              />
            </template>
            {{ CHECK_JUDGE_DICT[key] }}
          </van-radio>
        </van-radio-group>
      </div>
    </div>
    <!-- <div class="btn-box space-between">
      <van-button type="primary" color="#055C9D" round @click="toggleDefectPopup">添加缺陷记录</van-button>
      <van-button type="primary" color="#055C9D" plain round @click="toggleRemarkPopup">添加备注</van-button>
    </div> -->
    <defect-list-popup
      :show="isShowDefectListPop"
      :item-index-list="itemIndexList"
      @on-close="toggleDefectPopup"
    ></defect-list-popup>
    <remark-list-popup
      :show="isShowRemarkListPop"
      :item-index-list="itemIndexList"
      :item-obj="itemObj"
      @on-close="toggleRemarkPopup"
    ></remark-list-popup>
  </div>
</template>

<script>
import {
  CHECK_TYPE_P_BAR,
  CHECK_TYPE_P_ITEM,
  CHECK_TYPE_S_BAR,
  CHECK_TYPE_S_ITEM,
  CHECKPOINT_ATTRIBUTE,
  CHECKPOINT_CUSTOM,
} from "@/constants";
import DefectListPopup from "@/components/Defect/DefectListPopup.vue";
import RemarkListPopup from "@/components/Remark/RemarkListPopup.vue";
import {
  CHECK_TYPE_BAR,
  CHECK_TYPE_CUSTOM,
  CHECK_TYPE_NORMAL,
  CHECK_JUDGE_DICT,
} from "@/constants";
import { downloadFile, postAction } from "@/api/manage";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { axios } from "@/utils/request";

export default {
  name: "CheckForm",
  props: {
    itemObj: {
      type: Object,
      default() {
        return {};
      },
    },
    itemIndexList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { RemarkListPopup, DefectListPopup },
  inject: ["singleProductId", "orderItemId"],
  data() {
    return {
      CHECK_TYPE_BAR,
      CHECK_TYPE_NORMAL,
      CHECK_TYPE_CUSTOM,
      CHECK_JUDGE_DICT,
      isShowDefectListPop: false,
      isShowRemarkListPop: false,
      fileList: [],
      imgList: [],
      photoExmpleUrlList: [],
      form: {
        scanList: [],
        photoList: [],
        checkpointJudge: "",
      },
      localList: [],
      cacheKey: "",
    };
  },
  computed: {
    ...mapGetters(["orderItemDetail"]),
    isBar() {
      return [CHECK_TYPE_P_BAR, CHECK_TYPE_S_BAR].includes(
        this.itemObj.checkType
      );
    },
    isItem() {
      return [CHECK_TYPE_P_ITEM, CHECK_TYPE_S_ITEM].includes(
        this.itemObj.checkType
      );
    },
    remarkText() {
      if (this.itemObj?.taskRemarkList?.length) {
        let remarkList = _.cloneDeep(this.itemObj.taskRemarkList);
        return remarkList
          .filter((item) => item.description)
          .map((item) => item.description)
          .join(";");
      } else return "无";
    },
    photoDescIndex() {
      if (this.itemObj.photoNumber || this.itemObj.photoNumber === 0) {
        return 2;
      } else return 1;
    },
    exampleIndex() {
      if (this.itemObj.photoDescription) {
        return this.photoDescIndex + 1;
      } else {
        return this.photoDescIndex;
      }
    },
  },
  watch: {
    "itemObj.fileUrl": {
      immediate: true,
      handler: function () {
        if (this.itemObj?.fileUrl) {
          let fileList = this.itemObj.fileUrl.split(",");
          this.fileList = fileList?.map((item) => {
            let path = item.split("/");
            return {
              url: item,
              name: decodeURI(path[path.length - 1]),
            };
          });
        }
      },
    },
    "itemObj.imgUrl": {
      immediate: true,
      handler: function () {
        if (this.itemObj?.imgUrl) {
          this.imgList = this.itemObj.imgUrl.split(",").map((item) => {
            return {
              url: item,
              content: item,
            };
          });
        } else this.imgList = [];
      },
    },
    "itemObj.photoExmpleUrl": {
      immediate: true,
      handler: function () {
        if (this.itemObj?.photoExmpleUrl) {
          this.photoExmpleUrlList = this.itemObj.photoExmpleUrl
            .split(",")
            .map((item) => {
              return {
                url: item,
                content: item,
              };
            });
        } else {
          this.photoExmpleUrlList = [];
        }
      },
    },
    // // 'itemObj.id': {
    // itemObj: {
    //   immediate: true,
    //   deep:true,
    //   handler: function () {
    //     console.log(5555)
    //     // 判断本地缓存回显
    //     this.cacheKey = `${this.orderItemId}_cache`
    //     let localListStr = window.localStorage.getItem(this.cacheKey)
    //     this.localList = localListStr ? JSON.parse(localListStr) : []
    //     let index = this.localList.findIndex(item => item.id === this.itemObj.id)
    //     console.log(this.localList)
    //     if (index > -1) {
    //       console.log(6666)
    //       let form = this.localList[index]
    //       this.form = form
    //       // this.$emit('resultChange', this.form.checkpointJudge)
    //       return
    //     }
    //     console.log(this.itemObj)
    //     // 接口数据回显
    //     // if(this.itemObj?.taskInspection?.id){
    //     if(this.itemObj?.taskInspection){
    //       let form = this.itemObj.taskInspection
    //       if (form.taskInspectionExpands?.length > 0) {
    //         this.form.scanList = form.taskInspectionExpands.map(item => {
    //           return {
    //             id: item.id,
    //             imgData: item.imgData,
    //             scanTime: item.scanTime,
    //           }
    //         })
    //       }
    //       this.form.checkpointJudge = form.checkpointJudge ? form.checkpointJudge : null
    //       if(form.images){
    //         this.form.photoList = form.images.split(',').map(item => {
    //           return {
    //             content: item,
    //             url: item
    //           }
    //         })
    //       } else {
    //         this.form.photoList = []
    //       }
    //       // this.$emit('resultChange', this.form.checkpointJudge)
    //     }
    //   }
    // },
  },
  methods: {
    setForm(itemObj) {
      // 判断本地缓存回显
      this.cacheKey = `${this.orderItemId}_cache`;
      let localListStr = window.localStorage.getItem(this.cacheKey);
      this.localList = localListStr ? JSON.parse(localListStr) : [];
      let index = this.localList.findIndex((item) => item.id === itemObj.id);
      console.log(index);
      if (index > -1) {
        let form = this.localList[index];
        this.form = form?.taskInspection;
        // this.$forceUpdate()
        return;
      }
      console.log(itemObj);
      if (itemObj?.taskInspection?.id) {
        let form = itemObj.taskInspection;
        if (form.taskInspectionExpands?.length > 0) {
          this.form.scanList = form.taskInspectionExpands.map((item) => {
            return {
              id: item.id,
              imgData: item.imgData,
              scanTime: item.scanTime,
            };
          });
        }
        this.form.checkpointJudge = form.checkpointJudge
          ? form.checkpointJudge
          : null;
        if (form.images) {
          this.form.photoList = form.images.split(",").map((item) => {
            return {
              content: item,
              url: item,
            };
          });
        } else {
          this.form.photoList = [];
        }
        console.log(form);
        console.log(this.form);
        // this.$emit('resultChange', this.form.checkpointJudge)
      } else {
        this.form = itemObj.taskInspection || {
          scanList: [],
          photoList: [],
          checkpointJudge: "",
        };
      }
    },
    validate() {
      let checkName = `${this.itemObj.checkName}-${this.itemObj.checkPointName}`;
      if (this.itemObj.checkType === CHECK_TYPE_P_ITEM) {
        checkName = `${this.itemObj.checkName}-${this.itemObj.checkPointName}`;
      }
      // 校验扫码记录
      if (
        this.itemObj.haveNumberRequire &&
        this.itemObj.number &&
        this.form.scanList.length < this.itemObj.number
      ) {
        this.Toast.fail(`${checkName}扫描记录未达到要求`);
        return false;
      }
      // 校验拍照记录
      if (
        this.itemObj.havePhotograghRequire === "Y" &&
        this.itemObj.photoNumber &&
        this.form.photoList.length < this.itemObj.photoNumber
      ) {
        this.Toast.fail(`${checkName}拍照记录未达到要求`);
        return false;
      }
      // 校验检验结果
      if (!this.form.checkpointJudge) {
        this.Toast.fail(`${checkName}未选择检查点判定结果`);
        return false;
      }
      // 校验拍照记录是否上传完成
      if (
        this.form.photoList &&
        this.form.photoList.find((item) => !item.url)
      ) {
        console.log(
          "等待form:",
          this.form.photoList.find((item) => !item.url),
          this.form
        );
        this.Toast.fail(`请等待${checkName}的图片上传完成`);
        return false;
      }
      return true;
    },
    handleScan() {
      let that = this;
      if (!that.form.scanList) this.form.scanList = [];
      // this.form.scanList.push({
      //   scanTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   imgData: 'EAN13,6907992507095'
      // })
      // this.$emit('change', {...this.form, id: this.itemObj.id})
      // return
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          let scanTime = moment().format("YYYY-MM-DD HH:mm:ss");
          that.form.scanList.push({
            imgData: res.resultStr,
            scanTime,
          });
          that.$emit("change", { ...that.form, id: that.itemObj.id });
          that.$forceUpdate();
        },
      });
    },
    toggleDefectPopup() {
      this.isShowDefectListPop = !this.isShowDefectListPop;
    },
    toggleRemarkPopup() {
      this.isShowRemarkListPop = !this.isShowRemarkListPop;
    },
    handleChange() {
      this.$emit("change", { ...this.form, id: this.itemObj.id });
    },
    handleReadImg(info) {
      const data = new FormData();
      data.append("file", info.file);
      axios({
        url: "jeecg-system/platform/common/upload_file",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data", // 文件上传
        },
      })
        .then((res) => {
          console.log("res:", res);
          console.log("res.message:", res.message);
          if (res.success) {
            info.url = res.message;
            info.content = res.message;
            console.log("info:", info);
            this.$emit("change", { ...this.form, id: this.itemObj.id });
          } else {
            // 上传失败后，删除该图片
            let index = this.form.photoList.findIndex(
              (item) => item.content === info.content
            );
            if (index > -1) {
              this.form.photoList.splice(index, 1);
            }
            this.Toast.fail(res.message || "上传图片失败!");
          }
        })
        .catch((error) => {
          console.log("error:", error);
          // 上传失败后，删除该图片
          let index = this.form.photoList.findIndex(
            (item) => item.content === info.content
          );
          if (index > -1) {
            this.form.photoList.splice(index, 1);
          }
          this.Toast.fail("上传图片失败");
        });
    },
    handleResultChange() {
      this.$emit("change", { ...this.form, id: this.itemObj.id });
      this.$emit("resultChange", this.form.checkpointJudge);
    },
    handleImgPreview(index) {
      this.ImagePreview({
        images: this.itemObj.imgList,
        showIndex: true,
        loop: false,
        startPosition: index,
      });
    },
    handleDownload(url) {
      // downloadFile(url)
    },
  },
};
</script>

<style lang="less" scoped>
.check-form {
  padding: 16px;
  margin-right: -20px;
  padding-right: 20px;
  max-height: calc(85vh - 214px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .info-item {
    margin-bottom: 8px;
    display: flex;

    .label {
      flex-shrink: 0;
      width: 80px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0;
      &.required {
        position: relative;
      }
      &.required::after {
        content: "*";
        color: #f75658;
        margin-left: 2px;
        position: absolute;
      }
    }

    .value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      letter-spacing: 0;
      line-height: 22px;
      white-space: pre-line;
      max-width: calc(100% - 80px);

      .file {
        .name {
          width: calc(100% - 60px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .btn {
          width: 40px;
          color: #055c9d;
          font-size: 12px;
        }
      }
    }

    .value-box {
      width: calc(100% - 80px);

      .value {
        max-width: 100%;
      }
    }

    .tip {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0;
      line-height: 20px;
      margin: 8px 0;
    }

    .demo-img-box {
      display: flex;

      img {
        height: 70px;
        margin-right: 8px;
      }
    }

    .radio-group {
      width: calc(100% - 80px);

      .img-icon {
        width: 12px;
        height: 12px;
      }

      ::v-deep {
        .van-radio {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }

        .van-radio__label {
          margin-left: 4px;
          height: 22px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }
    }
  }

  .scan-box {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);

    .scan-btn {
      margin-left: 80px;
      width: 70px;
      height: 70px;
      background: #f9f9f9;
      border-radius: 2px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .btn-box {
    margin-top: 16px;

    .van-button {
      width: calc((100% - 20px) / 2);
    }
  }
}
</style>
