<template>
  <custom-popup
      class="defect-list"
      :show="show"
      title="缺陷列表"
      @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <no-data v-if="!loading && defectList && defectList.length === 0"></no-data>
      <div v-for="(item, index) in defectList" :key="index" class="list-item space-between vertical-center"
           @click="handleItemClick(item, index)">
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div>
            <span>{{ item.firstCategory }}{{item.secondaryCategory ? '/' + item.secondaryCategory : ''}}{{item.tertiaryCategory ? '/' + item.tertiaryCategory : ''}}</span>
            <span v-if="item.aqlCri">致命CRI:{{ item.aqlCri }}</span>
            <span v-if="item.aqlMaj">严重MAJ:{{ item.aqlMaj }}</span>
            <span v-if="item.aqlMin">轻微MIN:{{ item.aqlMin }}</span>
          </div>
        </div>
        <img src="~@/assets/svg/icon-arrow-right.svg" alt="">
      </div>
    </div>
    <van-button
      class="new-btn"
      size="large"
      :class="{'disabled': readonly}"
      type="primary"
      color="#055C9D"
      block
      @click="handleAdd"
    >
      添加缺陷
    </van-button>
    <defect-detail-popup
        ref="detail"
        :show="isShowDetail"
        @on-close="toggleDetailPopup"
        @on-edit="handleEdit"
        @on-del="handleDel"
    >
    </defect-detail-popup>
    <new-defect-popup
        ref="form"
        :show="isShowForm"
        :item-index-list="itemIndexList"
        @on-close="toggleFormPopup"
        @on-ok="handleFormOk"
    >
    </new-defect-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import DefectDetailPopup from '@/components/Defect/DefectDetailPopup.vue';
import NewDefectPopup from '@/components/Defect/NewDefectPopup.vue';
import NoData from '@/components/Common/NoData';
import {deleteAction, getAction} from '@/api/manage';
import {mapGetters} from 'vuex';

export default {
  name: 'DefectListPopup',
  components: {NoData, NewDefectPopup, DefectDetailPopup, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    itemIndexList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  inject: ['singleProductId'],
  data() {
    return {
      defectList: [],
      isShowDetail: false,
      isShowForm: false,
      selectIndex: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['orderItemDetail', 'checkItemList'])
  },
  watch: {
    show: function () {
      if (this.show) {
        this.getDefectList()
      }
    }
  },
  methods: {
    toggleDetailPopup() {
      this.isShowDetail = !this.isShowDetail
    },
    toggleFormPopup() {
      this.isShowForm = !this.isShowForm
    },
    handleItemClick(item, index) {
      this.selectIndex = index
      this.$refs.detail.disabled = this.readonly
      this.$refs.detail.form = item
      this.toggleDetailPopup()
    },
    handleAdd() {
      this.selectIndex = null
      this.$refs.form.init()
      this.toggleFormPopup()
    },
    handleEdit() {
      this.toggleDetailPopup()
      this.$refs.form.init(this.defectList[this.selectIndex])
      this.toggleFormPopup()
    },
    handleFormOk() {
      this.toggleFormPopup()
      this.getDefectList()
      this.$emit('change', this.defectList)
    },
    handleDel() {
      this.Dialog.confirm({
        title: '',
        message: '确认删除该缺陷？',
      }).then(async () => {
        const res = await deleteAction('/jeecg-system/task/taskDefect/delete', {
          id: this.defectList[this.selectIndex].id
        })
        if(res.success){
          this.getDefectList()
          this.toggleDetailPopup()
          this.$emit('change', this.defectList)
        }else{
          this.Toast.fail(res.message)
        }
      }).catch(async () => {

      });
    },
    async getDefectList() {
      this.loading = true
      const res = await getAction('/jeecg-system/task/taskDefect/list', {
        orderId: this.orderItemDetail.orderId,
        taskOrderItemId: this.orderItemDetail.id,
      })
      this.loading = false
      if (res.success) {
        this.defectList = res.result.records
      } else this.Toast.fail(res.message)
    }
  },
}
</script>

<style scoped lang="less">
.defect-list {

  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #DFE4EB;

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .info {
      font-size: 14px;

      .name {
        color: rgba(#000, 0.9);
      }

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
