<template>
  <card class="task-info-card" title="任务信息">
    <field-item label="验货类型">
      <span>{{taskDetail.yanhuoType_dictText}}</span>
    </field-item>
    <field-item label="验货状态">
      <span>{{TASK_STATUS_DICT[taskDetail.taskStatus]}}</span>
    </field-item>
    <field-item label="验货开始时间">
      <span>{{ taskDetail.startDate }}</span>
    </field-item>
    <field-item label="验货结束时间">
      <span>{{ taskDetail.endDate }}</span>
    </field-item>
    <field-item label="验货员">
      <span>{{ taskDetail.yanhuoUserName }}</span>
    </field-item>
    <field-item label="关联供应商">
      <span>{{ taskDetail.gongyinshangUserName }}</span>
    </field-item>
    <field-item label="验货地址与联系人">
      <div>
        <div class="sub-item vertical-center">
          <span>{{ taskDetail.relationName }}</span>
          <span class="highlight phone-num">{{ taskDetail.relationTelephone }}</span>
          <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt=""
               @click="handleCall(taskDetail.relationTelephone)">
        </div>
        <div class="sub-item">{{ taskDetail.companyName }}</div>
        <div class="sub-item highlight" @click="handleAddrClick">{{ taskDetail.companyArea }}{{ taskDetail.companyDetailAdress }}</div>
      </div>
    </field-item>
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import {mapGetters} from 'vuex';
import {TASK_STATUS_DICT} from '@/constants';

export default {
  name: "TaskInfoCard",
  components:{Card, FieldItem},
  data(){
    return {
      TASK_STATUS_DICT
    }
  },
  computed: {
    ...mapGetters(['taskDetail']),
  },
  methods: {
    handleCall(phone) {
      window.location.href = `tel:${phone}`
    },
    handleAddrClick() {
      if(this.taskDetail.lat && this.taskDetail.lng){
        this.Dialog.confirm({
          message: '是否跳转到微信地图？',
          confirmButtonColor: '#055C9D'
        }).then(()=>{
          wx.openLocation({
            latitude: parseFloat(this.taskDetail.lat), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(this.taskDetail.lng), // 经度，浮点数，范围为180 ~ -180。
            name: '', // 位置名
            address: this.taskDetail.companyArea + this.taskDetail.companyDetailAdress, // 地址详情说明
            scale: 12, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          });
        }).catch(()=>{

        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.task-info-card {
  .sub-item {
    margin-bottom: 8px;
  }

  .highlight {
    color: #055C9D;
  }

  .phone-num {
    margin: 0 8px;
  }

  .icon-phone {
    width: 12px;
    height: 12px;
  }
}
</style>
