<template>
  <custom-popup
      class="new-defect"
      :show="show"
      :title="isEdit ? '编辑缺陷' : '录入新缺陷'"
      @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="缺陷类别" required>
        <el-select v-model="form.category" readonly class="check-category" size="small" placeholder="请选择" @focus="isShowCategory = true"></el-select>
      </field-item>
      <field-item label="缺陷名称" required>
        <el-input v-model="form.name" placeholder="请输入" size="small" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="缺陷描述" required>
        <el-input v-model="form.description" placeholder="请输入" type="textarea" :rows="3" :maxlength="100" @blur="handleInputBlur"/>
      </field-item>
      <field-item>
        <van-uploader
            v-model="form.imgList"
            :after-read="handleReadImg"
            :preview-size="70"
            :max-count="10"
            :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </field-item>
      <field-item label="缺陷统计" required>
        <div class="statistic-wrapper">
          <div class="statistic-item vertical-center">
            <div class="name">致命（ CRI ）</div>
            <counter v-model="form.aqlCri"/>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">严重（ MAJ）</div>
            <counter v-model="form.aqlMaj"/>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">轻微（ MIN ）</div>
            <counter v-model="form.aqlMin"/>
          </div>
        </div>
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
<!--      <van-button class="btn" color="#055C9D" plain @click="$emit('on-close')">取消</van-button>-->
      <van-button :loading="loading" size="large" class="btn" color="#055C9D" type="primary" @click="handleOk">
        {{ isEdit ? '确定修改' : '确定添加' }}
      </van-button>
    </div>
    <van-popup v-model="isShowCategory" round position="bottom" get-container="body">
      <van-cascader
          v-model="cascaderValue"
          active-color="#055C9D"
          title="缺陷类别"
          :field-names="fieldNames"
          :options="options"
          @close="isShowCategory = false"
          @finish="onFinish"
      />
    </van-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import Counter from '@/components/Common/Counter.vue'
import FieldItem from "@/components/Common/FieldItem.vue";
import {mapGetters} from 'vuex';
import {axios} from '@/utils/request';
import {postAction, getAction, putAction} from '@/api/manage';
import {debounce} from 'lodash'

export default {
  name: 'NewDefectPopup',
  components: {FieldItem, Counter, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    itemIndexList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  inject: ['singleProductId'],
  data() {
    return {
      form: {
        category: null,
        firstCategory: null,
        secondaryCategory: null,
        tertiaryCategory: null,
        name: null,
        description: null,
        imgList: [],
        aqlCri: 0,
        aqlMaj: 0,
        aqlMin: 0,
      },
      isEdit: false,
      isShowCategory: false,
      cascaderValue: '',
      options: [],
      fieldNames: {
        text: 'name',
        value: 'name',
        children: 'children',
      },
      selectedOptions: [],
      loading: false,
    }
  },
  created() {
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['orderItemDetail', 'checkItemList'])
  },
  methods: {
    init(obj) {
      this.cascaderValue = ''
      if (obj) {
        this.form = {
          ...obj,
          category: obj.firstCategory ? `${obj.firstCategory}/${obj.secondaryCategory}/${obj.tertiaryCategory}` : null,
          imgList: obj.img ? obj.img.split(',').map(item => {
            return {
              content: item,
              url: item
            }
          }) : [],
        }
        this.isEdit = true
      } else {
        this.form = {
          category: null,
          firstCategory: null,
          secondaryCategory: null,
          tertiaryCategory: null,
          name: null,
          description: null,
          imgList: [],
          aqlCri: 0,
          aqlMaj: 0,
          aqlMin: 0,
        }
        this.isEdit = false
      }
    },
    handleReadImg(info) {
      const data = new FormData()
      data.append('file', info.file)
      axios({
        url: 'jeecg-system/platform/common/upload_file',
        method: "post",
        data,
        headers: {
          'Content-Type': 'multipart/form-data',  // 文件上传
        }
      }).then(res => {
        if (res.success) {
          info.url = res.message
          info.content = res.message
        } else {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(item => item.content === info.content)
          if(index > -1){
            this.form.imgList.splice(index, 1)
          }
          this.Toast.fail(res.message || "上传图片失败!")
        }
      }).catch(error => {
        // 上传失败后，删除该图片
        let index = this.form.imgList.findIndex(item => item.content === info.content)
        if(index > -1){
          this.form.imgList.splice(index, 1)
        }
        this.Toast.fail("上传图片失败")
      })
    },
    handleInputBlur(){
      window.scroll(0, 0);
    },
    handleOk : debounce(async function() {
      if(!this.form.category){
        this.Toast.fail('请选择缺陷类别')
        return
      }
      if(!this.form.name){
        this.Toast.fail('请输入缺陷名称')
        return
      }
      if(!this.form.description && !this.form.imgList.length ){
        this.Toast.fail('请输入缺陷描述或上传相关图片')
        return
      }
      if(!this.form.aqlCri && !this.form.aqlMaj && !this.form.aqlMin){
        this.Toast.fail('至少有一个缺陷统计')
        return
      }
      // 校验图片是否上传完成
      if(this.form.imgList.find(item => !item.url)){
        this.Toast.fail('请等待图片上传完成')
        return false
      }
      const url = this.isEdit ? '/jeecg-system/task/taskDefect/edit' : '/jeecg-system/task/taskDefect/add'
      const data = {
        aqlCri: this.form.aqlCri,
        aqlMaj: this.form.aqlMaj,
        aqlMin: this.form.aqlMin,
        description: this.form.description,
        firstCategory: this.form.firstCategory,
        id: this.form.id,
        img: this.form.imgList.map(item => item.url).join(','),
        name: this.form.name,
        orderId: this.orderItemDetail.orderId,
        secondaryCategory: this.form.secondaryCategory,
        taskId: this.orderItemDetail.taskId,
        taskOrderItemId: this.orderItemDetail.id,
        tertiaryCategory: this.form.tertiaryCategory,
      }
      this.loading = true
      const res = this.isEdit ? await putAction(url, data) : await postAction(url, data)
      this.loading = false
      if(res.success){
        this.$emit('on-ok')
      }else{
        this.Toast.fail(res.message)
      }
    }, 500),
    onFinish({selectedOptions}) {
      this.isShowCategory = false;
      this.form.category = selectedOptions.map((option) => option.name).join('/');
      this.form.firstCategory = selectedOptions[0].name
      this.form.secondaryCategory = selectedOptions[1].name
      this.form.tertiaryCategory = selectedOptions[2].name
    },
    async getCategoryList(){
      const res = await getAction('/jeecg-system/task/taskDefect/getDefectCategory', {
        singleProductId: this.orderItemDetail.singleProductId,
      })
      if(res.success){
        this.options = res.result
      }else this.Toast.fail(res.message)
    }
  }
}
</script>

<style scoped lang="less">
.new-defect::v-deep {
  .check-select {
    width: calc(50% - 10px);
  }

  .check-category {
    width: 100%;
  }

  .statistic-item{
    margin-bottom: 8px;
  }

  .btn-wrapper {
    margin-top: 22px;

    .btn {
      //width: 163px;
      width: 100%;
    }
  }

  .el-textarea__inner {
    background-color: #F9F9F9;
    border: none;
  }
}
</style>
