<template>
  <card title="验货检查点">
    <div slot="title" class="title-btn-wrapper">
      <van-button plain color="#055C9D" size="mini" @click="toggleRemarkPopup">备注</van-button>
      <van-button plain color="#055C9D" size="mini" style="margin-left: 8px" @click="toggleDefectPopup">缺陷</van-button>
    </div>
    <custom-table :columns="columns" :table-data="tableData"/>
    <remark-list-popup :show="isShowRemark" readonly @on-close="toggleRemarkPopup"></remark-list-popup>
    <defect-list-popup :show="isShowDefect" readonly @on-close="toggleDefectPopup"></defect-list-popup>
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import CustomTable from "@/components/Common/CustomTable.vue";
import RemarkListPopup from '@/components/Remark/RemarkListPopup.vue';
import DefectListPopup from '@/components/Defect/DefectListPopup.vue';
import {getAction} from '@/api/manage';
import {CHECK_JUDGE_DICT} from '@/constants';


export default {
  name: "CheckResultTable",
  components: {DefectListPopup, RemarkListPopup, CustomTable, Card},
  props: {
    orderItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      CHECK_JUDGE_DICT,
      columns: [
        // '检查大项',
        // '检查子项',
        '检查项名称',
        '检查点名称',
        '验货结论',
      ],
      tableData: [
        ['条形码', '外观条', '合格'],
        ['纸箱', '', '不合格'],
        ['磨砂', '', '待定'],
        ['其他', '', '合格'],
      ],
      isShowRemark: false,
      isShowDefect: false,
    }
  },
  created() {
    this.getInspectionList()
  },
  methods: {
    async getInspectionList(){
      const res = await getAction('/jeecg-system/task/taskInspection/list', {
        taskOrderItemId: this.orderItemId,
        pageNo: 1,
        pageSize: -1,
      })
      if(res.success){
        console.log(res.result)
        this.tableData = res.result?.records.map(i => {
          // return [i.checkBigName, i.checkChildName,i.checkItemName, i.checkpointName, CHECK_JUDGE_DICT[i.checkpointJudge]]
          return [i.checkItemName, i.checkpointName, CHECK_JUDGE_DICT[i.checkpointJudge]]
        })
      }else this.Toast.fail(res.message)
    },
    toggleRemarkPopup() {
      this.isShowRemark = !this.isShowRemark
    },
    toggleDefectPopup() {
      this.isShowDefect = !this.isShowDefect
    },
  },
}
</script>

<style scoped>

</style>
