<template>
  <div class="task-check">
    <global-header title="单品验货" :back-func="handleNavBack"/>
    <div class="content">
<!--      <card :title="orderItemDetail.title">-->
<!--        <div class="basic-info">-->
<!--          <span class="item">申请数量: {{orderItemDetail.inspectQuantity}}</span>-->
<!--          <span class="item">抽检水平: {{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>-->
<!--          <span class="item">建议抽检: {{orderItemDetail. sampleQuantity}}</span>-->
<!--        </div>-->
<!--      </card>-->
<!--      <div class="step-wrapper">-->
<!--        <img :src="require(`@/assets/img/step-${step}.png`)" alt="">-->
<!--      </div>-->
      <component :is="stepComponent" :order-item-id="orderItemId" @on-nav-change="isBack = false" @on-next="handleNextStep(1)" @on-previous="handleNextStep(-1)"/>
    </div>
  </div>
</template>

<script>
import GlobalHeader from '@/components/Common/GlobalHeader.vue'
import Card from '@/components/Common/Card.vue'
import Step1 from "@/components/TaskCheck/Step1.vue"
import Step2 from "@/components/TaskCheck/Step2.vue"
import Step3 from "@/components/TaskCheck/Step3.vue"
import Step4 from "@/components/TaskCheck/Step4.vue"
import {getAction} from '@/api/manage';
import {SAMPLING_LEVEL_DICT} from '@/constants';
import inobounce from 'inobounce';

export default {
  name: "taskCheck",
  components: {Step4, Step3, Step2, Step1, GlobalHeader, Card},
  provide() {
    return {
      singleProductId: this.singleProductId,
      orderItemId: this.orderItemId,
    }
  },
  data() {
    return {
      SAMPLING_LEVEL_DICT,
      step: 1,
      orderItemDetail: {},
      orderItemId: this.$route.query.orderItemId,
      singleProductId: this.$route.query.singleProductId,
      taskDetail: {},
      taskId: this.$route.query.taskId,
      isBack: true,
    }
  },
  computed: {
    stepComponent() {
      switch (this.step) {
        case 1:
          return 'step1'
        case 2:
          return 'step2'
        case 3:
          return 'step3'
        case 4:
          return 'step4'
        default:
          return 'step1'
      }
    },
  },
  created() {
    let localStep = window.localStorage.getItem(`${this.orderItemId}_step`)
    if(localStep&&localStep!=1){
      this.step = parseInt(localStep, 10)
    }else if (this.$route.query.step) {
      this.step = parseInt(this.$route.query.step, 10)
    }
    if(this.step === 4){
      window.localStorage.removeItem(`${this.orderItemId}_step`)
    }
    this.getOrderItemDetail()
    this.getTaskDetail()
  },
  mounted() {
    let u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
  methods: {
    handleNavBack() {
      if(this.isBack){
        this.$router.back()
      }else{
        this.$router.replace({name: 'index', query: {...this.$route.query}})
      }
    },
    handleNextStep(step) {
      this.step += step
      window.localStorage.setItem(`${this.orderItemId}_step`, this.step)
      if(this.step === 4){
        window.localStorage.removeItem(`${this.orderItemId}_step`)
      }
      this.getOrderItemDetail()
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          step: `${this.step}`
        }
      })
    },
    async getOrderItemDetail() {
      this.Toast.allowMultiple()
      this.Toast.loading({
        message: '加载中',
      })
      const res = await getAction('/jeecg-system/task/taskOrderItem/queryById', {
        id: this.orderItemId
      })
      this.Toast.clear()
      if (res.success) {
        this.orderItemDetail = res.result
        this.orderItemDetail.title = `${res.result.orderNo}-${res.result.singleProductNo}-${res.result.singleProductName}`
        this.$store.state.orderItemDetail = this.orderItemDetail
      } else {
        this.Toast.fail(res.message)
      }
    },
    async getTaskDetail() {
      const res = await getAction('/jeecg-system/admin/taskBaseInfo/queryById', {
        id: this.taskId
      })
      if (res.success) {
        this.taskDetail = res.result
        this.$store.state.taskDetail = this.taskDetail
      } else {
        this.Toast.fail(res.message)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.content {
  //height: calc(100vh - 44px);
  //background: #F9F9F9;
  //overflow-y: auto;
  //-webkit-overflow-scrolling:touch;
  //padding: 0 16px 150px;
  //box-sizing: border-box;

  ::v-deep .step-content{
    height: calc(100vh - 44px);
    padding: 0 16px 150px;
    box-sizing: border-box;
    background: #F9F9F9;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
  }

  //.basic-info {
  //  font-size: 14px;
  //  color: rgba(0, 0, 0, 0.40);
  //  line-height: 20px;
  //
  //  .item {
  //    margin-right: 8px;
  //  }
  //}
  //
  //.step-wrapper {
  //  margin: 16px 0;
  //
  //  img {
  //    width: 100%;
  //    object-fit: cover;
  //  }
  //}
}
</style>
