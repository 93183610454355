<template>
  <custom-popup
    class="new-check-point"
    :show="show"
    :title="title"
    @on-close="show = false"
  >
    <!-- <div class="content"> -->
    <check-form
      ref="checkForm"
      :item-obj="singleProduct"
      :item-index-list="[]"
    ></check-form>
    <div class="btn-wrapper space-between" style="margin-top: 0">
      <van-button
        class="btn"
        color="#055C9D"
        @click="$refs.checkForm.toggleDefectPopup()"
        plain
        >添加缺陷记录</van-button
      >
      <van-button
        class="btn"
        color="#055C9D"
        @click="$refs.checkForm.toggleRemarkPopup()"
        plain
        >添加备注</van-button
      >
    </div>
    <div class="btn-wrapper space-between">
      <van-button class="block-btn" color="#055C9D" block @click="handSure"
        >确定</van-button
      >
    </div>
    <!-- </div> -->
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import CheckForm from "@/components/TaskCheck/CheckForm.vue";

export default {
  name: "TaskSingleProductPopup",
  components: { CustomPopup, CheckForm },
  props: {
    singleProductId: {
      type: String,
      default: "",
    },
    productBaseInfoId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      show: false,
      singleProduct: {},
      loading: false,
    };
  },

  methods: {
    setCheckForm(item) {
      setTimeout(() => {
        this.$refs.checkForm.setForm(item);
      });
    },
    handSure() {
      console.log(this.$refs.checkForm.form);
      if (this.$refs.checkForm.validate()) {
        this.show = false;
        if (this.singleProduct.taskInspection) {
          this.singleProduct = JSON.parse(
            JSON.stringify(
              Object.assign(
                this.singleProduct.taskInspection,
                this.$refs.checkForm.form
              )
            )
          );
        } else
          this.singleProduct.taskInspection = JSON.parse(
            JSON.stringify(this.$refs.checkForm.form)
          );

        this.$emit("ok", this.singleProduct);
        setTimeout(() => {
          // this.singleProduct = {};
          this.$refs.checkForm.form = {
            scanList: [],
            photoList: [],
            checkpointJudge: "",
          };
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="less">
.new-check-point::v-deep {
  overflow: hidden;
  .btn-wrapper {
    margin-top: 22px;

    .btn {
      width: 163px;
    }
  }
}
</style>
