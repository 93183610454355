<template>
  <custom-popup
    class="new-remark"
    :show="show"
    :title="isEdit ? '编辑备注' : '添加备注'"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="检查点" required>
        <el-select
          v-model="form.checkName"
          readonly
          class="check-category"
          size="small"
          placeholder="请选择检查点"
          @focus="isShowCategory = true"
        ></el-select>
      </field-item>
      <field-item label="备注描述" required>
        <el-input
          v-model="form.description"
          placeholder="请输入"
          type="textarea"
          :rows="3"
          @blur="handleInputBlur"
        />
      </field-item>
      <field-item>
        <van-uploader
          v-model="form.imgList"
          :after-read="handleReadImg"
          :preview-size="70"
          :max-count="10"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
<!--      <van-button class="btn" color="#055C9D" plain @click="$emit('on-close')">取消</van-button>-->
      <van-button
        :loading="loading"
        class="btn"
        size="large"
        color="#055C9D"
        type="primary"
        @click="handleOk"
        >
        {{ isEdit ? "确定修改" : "确定添加" }}
      </van-button>
    </div>
    <van-popup
      v-model="isShowCategory"
      round
      position="bottom"
      get-container="body"
    >
      <van-cascader
        v-model="cascaderValue"
        active-color="#055C9D"
        title="检查点"
        :field-names="fieldNames"
        :options="options"
        @close="isShowCategory = false"
        @finish="onFinish"
      />
    </van-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import { mapGetters } from "vuex";
import { formatCheckItemToCascader } from "@/utils/util";
import { axios } from "@/utils/request";
import { postAction, putAction } from "@/api/manage";
import { debounce } from "lodash";

export default {
  name: "NewRemarkPopup",
  components: { FieldItem, CustomPopup },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    itemObj: {
      type: Object,
      default() {
        return {};
      },
    },
    itemIndexList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  inject: ["singleProductId"],
  data() {
    return {
      form: {
        checkName: "",
        description: "",
        imgList: [],
      },
      isEdit: false,
      isShowCategory: false,
      cascaderValue: "",
      options: [],
      fieldNames: {
        text: "itemName",
        value: "itemName",
        children: "children",
      },
      selectedOptions: [],
      selectedIndex: [],
      obj: {},
      loading: false,
      checkData: {},
    };
  },
  computed: {
    ...mapGetters(["orderItemDetail", "checkItemList"]),
  },
  methods: {
    init(obj) {
      this.obj = obj;
      this.checkData = obj;
      this.cascaderValue = "";
      this.selectedIndex = [];
      this.isShowCategory = false;
      if (obj) {
        // let checkName = obj.checkBigName + '/' + obj.checkChildName + '/' + obj.checkItemName
        // if(!obj.checkBigName){
        //   checkName = `${obj.checkItemName}/${obj.checkpointName}`
        // }
        // if(obj.checkBigName === '其他' && obj.checkChildName === '其他' && obj.checkItemName === '其他'){
        //   checkName = '其他'
        // }
        this.form = {
          checkName: obj.checkItemName + "-" + obj.checkpointName,
          description: obj.description,
          imgList: obj.img
            ? obj.img.split(",").map((item) => {
                return {
                  content: item,
                  url: item,
                };
              })
            : [],
          id: obj.id,
        };
        this.isEdit = true;
      } else {
        this.form = {
          checkName: "",
          description: "",
          imgList: [],
        };
        if (this.itemObj?.id) {
          this.checkData= JSON.parse(JSON.stringify(this.itemObj))
          this.form.checkName = `${this.itemObj.checkName}/${this.itemObj.checkPointName}`
        }
        // if(this.itemIndexList?.length){
        //   let index1 = this.itemIndexList[0]
        //   let index2 = this.itemIndexList[1]
        //   let name1 = this.checkItemList[index1].itemName
        //   let name2 = this.checkItemList[index1].children[index2].itemName
        //   if(this.itemIndexList.length === 3){
        //     let name3 = this.checkItemList[index1].children[index2].children[this.itemIndexList[2]].itemName
        //     this.form.checkName = `${name1}/${name2}/${name3}`
        //   }else{
        //     this.form.checkName = `${name1}/${name2}`
        //   }
        // }
        this.isEdit = false;
      }
      this.options = this.checkItemList.map((i) => {
        i.itemName = i.checkName + "-" + i.checkPointName;
        return i;
      });
      this.options.push({
        itemName: "其他",
        children: null,
        index: this.options.length,
      });
    },
    handleInputBlur() {
      window.scroll(0, 0);
    },
    handleReadImg(info) {
      const data = new FormData();
      data.append("file", info.file);
      axios({
        url: "jeecg-system/platform/common/upload_file",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data", // 文件上传
        },
      })
        .then((res) => {
          if (res.success) {
            info.url = res.message;
            info.content = res.message;
          } else {
            // 上传失败后，删除该图片
            let index = this.form.imgList.findIndex(
              (item) => item.content === info.content
            );
            if (index > -1) {
              this.form.imgList.splice(index, 1);
            }
            this.Toast.fail(res.message || "上传图片失败!");
          }
        })
        .catch((error) => {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(
            (item) => item.content === info.content
          );
          if (index > -1) {
            this.form.imgList.splice(index, 1);
          }
          this.Toast.fail("上传图片失败");
        });
    },
    handleOk: debounce(async function () {
      if (!this.form.checkName) {
        this.Toast.fail("请选择检查点");
        return;
      }
      if (!this.form.description && !this.form.imgList.length) {
        this.Toast.fail("请输入备注描述或上传相关图片");
        return;
      }
      // 校验图片是否上传完成
      if (this.form.imgList.find((item) => !item.url)) {
        this.Toast.fail("请等待图片上传完成");
        return false;
      }
      const url = this.isEdit
        ? "/jeecg-system/task/taskRemark/edit"
        : "/jeecg-system/task/taskRemark/add";
      // let indexList = this.selectedIndex?.length ? this.selectedIndex : this.itemIndexList
      // let checkItem = {}
      // if(indexList.length === 2){
      //    checkItem = this.checkItemList[indexList[0]].children[indexList[1]].children[0].children[0]
      // }else if(indexList.length === 3){
      //    checkItem = this.checkItemList[indexList[0]].children[indexList[1]].children[indexList[2]].children[0]
      // }
      // let checkData = {}
      // if(indexList.length === 3){
      //   checkData = {
      //     checkBigName: checkItem.checkBigName,
      //     checkChildName: checkItem.checkChildName,
      //     checkItemName: checkItem.checkName,
      //   }
      // }else if(indexList.length === 2){
      //   checkData = {
      //     checkItemName: checkItem.checkBigName,
      //     checkpointName: checkItem.checkChildName,
      //   }
      // }else if(indexList.length === 1){
      //   checkData = {
      //     checkBigName: '其他',
      //     checkChildName: '其他',
      //     checkItemName: '其他',
      //   }
      // }
      // if(this.isEdit && !this.selectedIndex.length){
      //   checkData = {
      //     checkBigName: this.obj.checkBigName,
      //     checkChildName: this.obj.checkChildName,
      //     checkItemName: this.obj.checkItemName,
      //     checkpointName: this.obj.checkpointName,
      //   }
      // }

      const data = {
        checkItemName: this.checkData.checkName || this.checkData.checkItemName,
        checkpointName:
          this.checkData.checkPointName || this.checkData.checkpointName,
        checkType: this.checkData.checkType,
        description: this.form.description,
        id: this.form.id,
        img: this.form.imgList.map((item) => item.url).join(","),
        orderId: this.orderItemDetail.orderId,
        taskId: this.orderItemDetail.taskId,
        taskOrderItemId: this.orderItemDetail.id,
      };
      this.loading = true;
      const res = this.isEdit
        ? await putAction(url, data)
        : await postAction(url, data);
      this.loading = false;
      if (res.success) {
        this.$emit("on-ok");
      } else {
        this.Toast.fail(res.message);
      }
    }, 500),
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      this.isShowCategory = false;
      this.form.checkName = selectedOptions
        .map((option) => option.itemName)
        .join("/");
      // this.selectedIndex = selectedOptions.map(option => option.index)
      if (selectedOptions[0].itemName == "其他") {
        this.checkData = { checkName: "其他", checkPointName: "其他" };
      } else this.checkData = selectedOptions[0];
    },
  },
};
</script>

<style scoped lang="less">
.new-remark::v-deep {
  .pop-content {
    min-height: 45vh;
  }
  .check-category {
    width: 100%;
  }

  .btn-wrapper {
    margin-top: 20px;

    .btn {
      //width: 163px;
      width: 100%;
    }
  }

  .el-textarea__inner {
    background-color: #f9f9f9;
    border: none;
  }
}
</style>
