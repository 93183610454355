<template>
  <div class="step1">
    <div class="step-content">
      <step-common-info :step="1"/>
      <card title="点数抽样">
        <field-item label="申请数量">
          <span>{{ orderItemDetail.inspectQuantity }}</span>
        </field-item>
        <field-item label="可检比例">
          <span>{{orderItemDetail.yanhuoNumberScale}}%</span>
        </field-item>
        <field-item label="点货数量">
          <counter v-model="form.orderQuantity"/>
        </field-item>
        <field-item label="抽检水平">
          <span>{{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>
        </field-item>
        <field-item label="建议抽检">
          <span>{{ orderItemDetail.sampleQuantity }}</span>
        </field-item>
        <field-item label="实际抽检">
          <counter v-model="form.actualSampleQuantity"/>
        </field-item>
        <field-item label="仓库/纸箱 堆箱照片">
          <div class="space-between">
            <van-uploader
                v-model="form.imgList"
                class="img-uploader"
                :preview-size="69"
                :upload-icon="require('@/assets/svg/icon-camera.svg')"
                :after-read="handleReadImg"
            />
          </div>
        </field-item>
        <field-item label="备注">
          <el-input v-model="form.inspectRemark" placeholder="请输入" type="textarea" :rows="3" @blur="handleInputBlur"/>
        </field-item>
      </card>
    </div>
    <div class="btn-wrapper space-between">
      <van-button class="btn" color="#055C9D" plain @click="$router.back()">未验货</van-button>
      <van-button :loading="btnLoading" loading-text="加载中" class="btn" color="#055C9D" type="primary" @click="submitForm">完成</van-button>
    </div>
  </div>
</template>

<script>
import StepCommonInfo from './StepCommonInfo';
import Card from "@/components/Common/Card.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import Counter from "@/components/Common/Counter.vue";
import {axios} from '@/utils/request';
import {mapGetters} from 'vuex';
import {putAction} from '@/api/manage';
import {SAMPLING_LEVEL_DICT} from '@/constants';

export default {
  name: "Step1",
  components: {Counter, FieldItem, Card, StepCommonInfo},
  data() {
    return {
      SAMPLING_LEVEL_DICT,
      form: {
        orderQuantity: 0,
        actualSampleQuantity: 0,
        imgList: [],
        inspectRemark: ''
      },
      btnLoading: false,
    }
  },
  computed: {
    ...mapGetters(['orderItemDetail']),
  },
  watch: {
    orderItemDetail: {
      immediate: true,
      handler: function () {
        this.form.orderQuantity = this.orderItemDetail.orderQuantity || 0
        this.form.actualSampleQuantity = this.orderItemDetail.actualSampleQuantity || 0
        this.form.inspectRemark = this.orderItemDetail.inspectRemark
        if(this.orderItemDetail?.images){
          this.form.imgList = this.orderItemDetail.images.split(',').map(item => {
            return {
              url: item,
              content: item,
            }
          })
        }else{
          this.form.imgList = []
        }
      }
    },
  },
  methods: {
    handleInputBlur(){
      window.scroll(0, 0);
    },
    handleReadImg(info) {
      const data = new FormData()
      data.append('file', info.file)
      axios({
        url: 'jeecg-system/platform/common/upload_file',
        method: "post",
        data,
        headers: {
          'Content-Type': 'multipart/form-data',  // 文件上传
        }
      }).then(res => {
        if (res.success) {
          info.url = res.message
          info.content = res.message
        } else {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(item => item.content === info.content)
          if(index > -1){
            this.form.imgList.splice(index, 1)
          }
          this.Toast.fail(res.message || "上传图片失败!")
        }
      }).catch(error => {
        // 上传失败后，删除该图片
        let index = this.form.imgList.findIndex(item => item.content === info.content)
        if(index > -1){
          this.form.imgList.splice(index, 1)
        }
        this.Toast.fail( "上传图片失败")
      })
    },
    async submitForm() {
      // 点货数量限制
      if(
          (this.orderItemDetail.inspectQuantity || 0) * (this.orderItemDetail.yanhuoNumberScale) > this.form.orderQuantity * 100
          || (this.form.orderQuantity > this.orderItemDetail.inspectQuantity)
      ){
        this.Toast('点货数量不可超过申请数量，也不可少于申请数量*可检比例')
        return
      }
      // 实际抽检数量限制
      if(this.form.actualSampleQuantity < 1 || this.form.actualSampleQuantity > this.form.orderQuantity){
        this.Toast('实际抽检数量不可少于1，也不可超过点货数量')
        return
      }
      // 校验照片是否上传完成
      if(this.form.imgList.find(item => !item.url)){
        this.Toast(`请等待图片上传完成`)
        return false
      }
      this.btnLoading = true
      const res = await putAction('/jeecg-system/task/taskOrderItem/edit', {
        id: this.orderItemDetail.id,
        orderQuantity: this.form.orderQuantity,
        actualSampleQuantity: this.form.actualSampleQuantity,
        images: this.form.imgList.map(item => item.url).join(','),
        inspectRemark: this.form.inspectRemark,
      })
      this.btnLoading = false
      if (res.success) {
        // this.$emit('on-next')
        this.Toast.success(res.message)
        setTimeout(() => {
          this.$router.back()
        }, 500);
      } else this.Toast.fail(res.message)
    },
  }
}
</script>

<style scoped lang="less">
.step1::v-deep {
  padding-bottom: 80px;

  .img {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .el-textarea__inner {
    background-color: #F9F9F9;
    border: none;
  }

  .btn-wrapper {
    width: 100vw;
    padding: 12px 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 22px;

    .btn {
      width: calc(50% - 8px);
    }
  }
}
</style>
