import { render, staticRenderFns } from "./DefectStatisticTable.vue?vue&type=template&id=3327661a&scoped=true&"
import script from "./DefectStatisticTable.vue?vue&type=script&lang=js&"
export * from "./DefectStatisticTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3327661a",
  null
  
)

export default component.exports