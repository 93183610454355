<template>
  <custom-popup
    class="remark-list"
    :show="show"
    title="备注列表"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <no-data
        v-if="!loading && remarkList && remarkList.length === 0"
      >
      </no-data>
      <div
        v-for="(item, index) in remarkList"
        :key="index"
        class="list-item space-between vertical-center"
        @click="handleItemClick(item, index)"
      >
        <div class="info">
          <div class="name">{{ item.description }}</div>
          <div>
            <span v-if="item.checkBigName"
            >检查点：
              <span
                v-if="
                  item.checkBigName === '其他' &&
                  item.checkChildName === '其他' &&
                  item.checkItemName === '其他'
                "
              >
                其他
              </span>
              <span v-else>
                {{ item.checkBigName }}-{{ item.checkChildName }}-{{ item.checkItemName }}
              </span>
            </span>
            <span v-if="item.checkpointName">
              检查点：{{ item.checkItemName }}-{{ item.checkpointName }}
            </span>
          </div>
        </div>
        <img src="~@/assets/svg/icon-arrow-right.svg" alt=""/>
      </div>
    </div>
    <van-button
      class="new-btn"
      size="large"
      :class="{ disabled: readonly }"
      type="primary"
      color="#055C9D"
      block
      @click="handleAdd"
    >
      添加备注
    </van-button>
    <remark-detail-popup
      ref="detail"
      :show="isShowDetail"
      @on-close="toggleDetailPopup"
      @on-edit="handleEdit"
      @on-del="handleDel"
    >
    </remark-detail-popup>
    <new-remark-popup
      ref="form"
      :show="isShowForm"
      :item-index-list="itemIndexList"
      :item-obj="itemObj"
      @on-close="toggleFormPopup"
      @on-ok="handleFormOk"
    >
    </new-remark-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import RemarkDetailPopup from "@/components/Remark/RemarkDetailPopup.vue";
import NewRemarkPopup from "@/components/Remark/NewRemarkPopup.vue";
import NoData from "@/components/Common/NoData";
import {getAction, deleteAction} from "@/api/manage";
import {mapGetters} from "vuex";

export default {
  name: "RemarkListPopup",
  components: {NoData, NewRemarkPopup, RemarkDetailPopup, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    itemObj: {
      type: Object,
      default() {
        return {};
      },
    },
    itemIndexList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  inject: ["singleProductId"],
  data() {
    return {
      remarkList: [],
      isShowDetail: false,
      isShowForm: false,
      selectIndex: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["orderItemDetail", "checkItemList"]),
  },
  watch: {
    show: function () {
      if (this.show) {
        this.getRemarkList();
      }
    },
  },
  methods: {
    toggleDetailPopup() {
      this.isShowDetail = !this.isShowDetail;
    },
    toggleFormPopup() {
      this.isShowForm = !this.isShowForm;
    },
    handleItemClick(item, index) {
      this.selectIndex = index;
      this.$refs.detail.disabled = this.readonly;
      this.$refs.detail.form = item;
      this.toggleDetailPopup();
    },
    handleAdd() {
      this.selectIndex = null;
      this.$refs.form.init();
      this.toggleFormPopup();
    },
    handleEdit() {
      this.toggleDetailPopup();
      this.$refs.form.init(this.remarkList[this.selectIndex]);
      this.toggleFormPopup();
    },
    handleFormOk() {
      this.toggleFormPopup();
      this.getRemarkList();
      this.$emit("change", this.remarkList);
    },
    handleDel() {
      this.Dialog.confirm({
        title: "",
        message: "确认删除该备注？",
      })
        .then(async () => {
          const res = await deleteAction(
            "/jeecg-system/task/taskRemark/delete",
            {
              id: this.remarkList[this.selectIndex].id,
            }
          );
          if (res.success) {
            this.getRemarkList();
            this.toggleDetailPopup();
            this.$emit("change", this.remarkList);
          } else {
            this.Toast.fail(res.message);
          }
        })
        .catch(() => {
        });
    },
    async getRemarkList() {
      this.loading = true;
      const res = await getAction("/jeecg-system/task/taskRemark/list", {
        orderId: this.orderItemDetail.orderId,
        taskOrderItemId: this.orderItemDetail.id,
      });
      this.loading = false;
      if (res.success) {
        this.remarkList = res.result.records || [];
      } else this.Toast.fail(res.message);
    },
  },
};
</script>

<style scoped lang="less">
.remark-list {
  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #dfe4eb;

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .info {
      font-size: 14px;

      .name {
        color: rgba(#000, 0.9);
      }

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
