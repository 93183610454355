<template>
  <div>
    <card :title="orderItemDetail.title">
      <div class="basic-info">
        <span class="item">申请数量: {{ orderItemDetail.inspectQuantity }}</span>
        <span class="item">抽检水平: {{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>
        <span class="item">建议抽检: {{ orderItemDetail.sampleQuantity }}</span>
      </div>
    </card>
    <div class="step-wrapper" v-if="step!=1">
      <img :src="require(`@/assets/img/step-${step}.png`)" alt="">
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SAMPLING_LEVEL_DICT} from '@/constants';
import Card from "@/components/Common/Card.vue";

export default {
  name: "StepCommonInfo",
  props: {
    step: {
      type: Number,
      default: 1,
    }
  },
  components: {
    Card
  },
  data(){
    return {
      SAMPLING_LEVEL_DICT,
    }
  },
  computed: {
    ...mapGetters(['orderItemDetail']),
  },
}
</script>

<style lang="less" scoped>
.basic-info {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.40);
  line-height: 20px;

  .item {
    margin-right: 8px;
  }
}

.step-wrapper {
  margin: 16px 0;

  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
